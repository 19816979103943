import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import FirebaseIcon from 'mdi-react/FirebaseIcon';
import withAuthFirebase from '../auth/withAuthFirebase';
// import { useAuth0 } from '../auth/withAuth0';
import Loading from '../Loading';
import LogInForm from './LogInForm';
import GoogleAuthBtn from '../../../containers/Account/AuthBtn/googleAuthBtn';
import FacebookAuthBtn from '../../../containers/Account/AuthBtn/fbAuthBtn';

import { auth } from '../../../redux/actions/authActions';

import { getAuth, signInWithPopup, GithubAuthProvider } from "firebase/auth";

import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

import { FaGithub } from 'react-icons/fa'
import { connect } from 'react-redux';

import Cookies from 'js-cookie'

import * as configadada from 'react-router-dom'

const auth0Icon = `${process.env.PUBLIC_URL}/img/auth0.svg`;

const LoginCard = ({ userData, dispatch }) => {
  // const {
  //   loginWithRedirect, loading,
  // } = useAuth0();
  // if (loading) {
  //   return (<Loading loading={loading} />);
  // }

  useEffect(() => {
      if (userData.fullName !== '') window.location.href = '/dashboard';
  })

  return (
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">Welcome to
            <span className="account__logo"> Easy
              <span className="account__logo-accent">DEV</span>
            </span>
          </h3>
          <h4 className="account__subhead subhead">Start your business easily</h4>
        </div>
        <LogInForm
          onSubmin
          form="log_in_form"
        />
        <div className="account__or">
          <p>only available methods:</p>
        </div>
        <div className="account__social">
          {/* <FacebookAuthBtn />
          <GoogleAuthBtn />
          <Button
            className="account__social-btn account__social-btn--firebase"
            onClick={changeIsOpenModalFireBase}
          ><FirebaseIcon />
          </Button>
          <Button className="account__social-btn account__social-btn--auth0" onClick={() => loginWithRedirect({})}>
            <img className="customizer__btn-icon" src={auth0Icon} alt="icon" />
          </Button> */}
          <Button className="account__social-btn account__social-btn--auth0 login__github_icon" onClick={() => {
            const provider = new GithubAuthProvider();

            signInWithPopup(getAuth(), provider)
              .then((result) => {
                const credential = GithubAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;

                const user = result.user;

                dispatch(auth({ name: user.displayName, avatar: user.photoURL }))

                Cookies.set('@EasyDev:user', JSON.stringify(user), {
                  expires: 2,
                  secure: process.env.NODE_ENV === 'production',
                })

                window.location.href = '/dashboard';
              }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                const email = error.email;
                const credential = GithubAuthProvider.credentialFromError(error);
              });
          }}>
            <FaGithub style={{ margin: '0px' }} />
          </Button>
        </div>
      </div>
    </div>
  );
};

LoginCard.propTypes = {
  changeIsOpenModalFireBase: PropTypes.func.isRequired,
};

export default connect((state) => ({ userData: state.user }))(LoginCard);
