import React from 'react';
import { connect } from 'react-redux';
import { Card, Col, CardBody } from 'reactstrap';
import Chat from './Chat';
import myContacts from './contacts';

const ChatCard = (props) => {
  return (
    <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <Chat
          contacts={myContacts}
          currentUser={{
            avatar: `${process.env.PUBLIC_URL}/img/ava.png`,
            name: 'Roman Johanson',
            userName: 'dragonKing',
          }}
        />
      </CardBody>
    </Card>
  </Col>
  )
};

const mapStateToProps = state => ({
  cryptoTable: state,
});

export default connect(mapStateToProps)(ChatCard);
