import { initializeApp } from "firebase/app";
import { GithubAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDHjUfF6E1uVgsReUb52fZidBiQgzgkk28",
  authDomain: "easydev-6bb98.firebaseapp.com",
  projectId: "easydev-6bb98",
  storageBucket: "easydev-6bb98.appspot.com",
  messagingSenderId: "399138321855",
  appId: "1:399138321855:web:5625d5b168f4c4a3885d0a",
  measurementId: "G-1CPK5F6DB3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const provider = new GithubAuthProvider();

export default app;

// export const githubProvider = firebase.auth.GithubAuthProvider