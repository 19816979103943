import Cookies from 'js-cookie';

export const getUser = () => {
    const cookieData = Cookies.get('@EasyDev:user');
    
    try {
        const data = JSON.parse(cookieData);
        return data;
    } catch(e) {
        return false;
    }
}