import { getAuth, updateProfile } from "firebase/auth";
import { auth, authError } from '../../redux/actions/authActions';
import Cookies from 'js-cookie';
import { getUser } from './getUser';

export const updateUser = ({ newName }, dispatch) => {
    updateProfile(getAuth().currentUser, {
        displayName: newName
    });

    const cookieData = getUser();

    cookieData.displayName = newName;

    Cookies.set('@EasyDev:user', JSON.stringify(cookieData), {
        expires: 2,
        secure: process.env.NODE_ENV === 'production',
    })

    dispatch(auth({ name: newName }));
}